import * as React from "react";
import { useMemo } from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Articles } from "../components/articles";
import { blogPosts } from "../utils/data";
import { Badge, Col, Container, Row } from "react-bootstrap";

const BlogPage: React.FC<PageProps> = ({ data }) => {
    const smartPlugs = useMemo(() => blogPosts.convert(data, "product", "smart plug"), [data]);
    const robotVacuums = useMemo(() => blogPosts.convert(data, "product", "robot vacuum"), [data]);

    return (
        <Layout activeKey={"/smart-home-products"}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className={"mainheading"}>
                            <h1>Explore Our Smart Home Products</h1>
                            <p className="lead">
                                Welcome to the <strong>Real Smart Home</strong> products page, where
                                you will find an extensive range of{" "}
                                <strong>smart home devices</strong> designed to make your life more
                                convenient, secure, and energy-efficient. Whether you’re looking to
                                enhance your home automation with the latest{" "}
                                <strong>smart plug</strong>, streamline your chores with a{" "}
                                <strong>robot vacuum</strong>, or upgrade your garage with a{" "}
                                <strong>smart garage</strong> system, we have the perfect solutions
                                for you.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className={"mainheading"}>
                            <h2>Robot Vacuums for Effortless Cleaning</h2>

                            <p className="lead">
                                Keeping your home clean has never been easier thanks to the advent
                                of the robot vacuum. These intelligent{" "}
                                smart home devices are designed to take the hassle
                                out of daily cleaning, allowing you to focus on more important
                                things. Our range of robot vacuums includes models
                                that offer advanced navigation, powerful suction, and the ability to
                                clean on a schedule that fits your lifestyle.
                            </p>

                            <p className="lead">
                                A robot vacuum is a perfect addition to any smart
                                home, especially if you’re looking to automate your cleaning
                                routine. These devices can be controlled through your smartphone,
                                allowing you to start a cleaning session from anywhere, even if
                                you’re not at home. Additionally, many{" "}
                                robot vacuums can be integrated with other{" "}
                                smart home devices, enabling them to work in tandem
                                with your home’s lighting or security system. For example, you can
                                set your robot vacuum to start cleaning when your
                                smart lights turn off or when you arm your security system as you
                                leave the house.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Articles articles={robotVacuums} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className={"mainheading"}>
                            <h2>Smart Garage Solutions</h2>

                            <p className="lead">
                                The modern home is all about convenience, and what better place to
                                start than your garage? Our selection of{" "}
                                smart garage products includes everything from
                                advanced <strong>garage door opener</strong> systems to fully
                                integrated smart garage setups. With a{" "}
                                <strong>smart garage door opener</strong>, you can open and close
                                your garage from anywhere, using your smartphone or a voice command.
                                This level of convenience ensures that you never have to worry about
                                whether you left the garage door open, offering peace of mind and
                                added security.
                            </p>

                            <p className="lead">
                                One of the standout features of our smart garage{" "}
                                systems is their ability to integrate with your existing{" "}
                                smart home devices. This means you can include your
                                garage in your home automation routines, such as automatically
                                closing the garage door at night or opening it when you arrive home.
                                Our garage door opener solutions are compatible
                                with most home automation systems, making them a versatile and
                                valuable addition to your smart home.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className={"mainheading"}>
                            <h2>Smart Plugs for Seamless Automation</h2>

                            <p className="lead">
                                Smart plugs are one of the most versatile and
                                cost-effective smart home devices available. They
                                allow you to control any appliance that plugs into a standard
                                outlet, turning even your most basic devices into part of your smart
                                home ecosystem. With a smart plug, you can automate
                                lamps, fans, coffee makers, and more, all from your smartphone or
                                via voice commands.
                            </p>

                            <p className="lead">
                                Our selection of smart plugs offers various
                                features, including energy monitoring, scheduling, and integration
                                with popular smart home platforms like Google Home and Amazon Alexa.
                                This allows you to create custom automation routines, such as
                                turning off all appliances when you leave the house or setting
                                specific devices to turn on just before you wake up.{" "}
                                Smart plugs are a simple yet powerful way to
                                enhance your home’s automation without needing to invest in
                                expensive new appliances.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Articles articles={smartPlugs} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className={"mainheading"}>
                            <h2>Integrating Smart Home Devices for a Connected Lifestyle</h2>

                            <p className="lead">
                                The beauty of a modern <strong>smart home</strong> lies in the
                                seamless integration of various smart home devices.
                                Whether it’s your smart plug controlling the
                                lights, your robot vacuum keeping the floors clean,
                                or your smart garage ensuring your car is secure,
                                each device can work together to create a fully automated home
                                environment.
                            </p>

                            <p className="lead">
                                At Real Smart Home, we offer a wide range of{" "}
                                smart home devices that are designed to be
                                compatible with each other and with popular home automation
                                platforms. This ensures that you can create a cohesive and efficient
                                smart home system tailored to your specific needs. Whether you’re
                                starting small with a few smart plugs or going all
                                in with a full smart garage and{" "}
                                robot vacuum setup, we have the products and
                                expertise to help you achieve your vision.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className={"mainheading"}>
                            <h2>Why Choose Real Smart Home for Your Smart Home Products?</h2>

                            <p className="lead">
                                At Real Smart Home, we are dedicated to providing
                                our customers with the best smart home devices on
                                the market. Our curated selection includes the latest in{" "}
                                smart garage technology, innovative{" "}
                                smart plugs, and reliable{" "}
                                <strong>robot vacuums</strong> that make home automation accessible
                                and effective for everyone. We understand that each home is unique,
                                which is why we offer products that can be easily integrated and
                                customized to suit your lifestyle.
                            </p>

                            <p className="lead">
                                Explore our product listings today and discover how our{" "}
                                smart home devices can transform your living space
                                into a connected, efficient, and comfortable environment. Whether
                                you’re looking to enhance your home’s security, improve energy
                                efficiency, or simply make your daily routine more convenient,{" "}
                                Real Smart Home has the perfect solutions for you.
                            </p>

                            <p className="lead">
                                Thank you for choosing Real Smart Home. We look
                                forward to helping you build the smart home of your dreams.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <h2>Categories</h2>
                        <ul className={"categories"}>
                            <li>
                                <a href={"/tag/smart-plug/"}>
                                    <Badge bg="primary">Smart plugs</Badge>
                                </a>
                            </li>
                            <li>
                                <a href={"/tag/robot-vacuum/"}>
                                    <Badge bg="primary">Robot vacuums</Badge>
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export const query = graphql`
    query Products {
        allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "product" } } }
            sort: { frontmatter: { date: DESC } }
        ) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    type
                    date(formatString: "MMMM DD, YYYY")
                    title
                    lead
                    category
                    featuredImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    }
`;

export const Head = () => (
    <Seo
        url={"/smart-home-products/"}
        title="Comprehensive Smart Home Products Database"
        shortTitle
        description={
            "Discover top-rated smart home devices with expert reviews, comparisons, and guides to help you choose the best tech for a smarter, more connected home."
        }
    />
);

export default BlogPage;
